//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from '@/vueI18n'
export default {
  name: 'app-iam-importer-page',
  computed:{
    language(){
      return i18n.locale
    }
  },
  methods:{
     i18n(key, args) {
      return this.$t(key, args);
    },
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.users') +
            ' | ' +
            this.i18n('iam.importer.title') 
    },
  },
};
